import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import api from "../../utils/ApiMethod";
import { AspectRatio } from "@chakra-ui/react";
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  VStack,
  Avatar,
  GridItem,
  Grid,
  Image,
  useColorModeValue,
  SimpleGrid,
  // keyframes,
  background,
  flexbox,
  position,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet-async";
import {API_KEY, channelID} from "../../utils/Secret"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";


// import key frame from chakra ui
const animation = keyframes`
from {
 background:inherit
}
to{
    background:gray.100
}
`;

const Videos = () => {
  const myAnimation = `${animation} infinite 1s`;

  const [data, setData] = useState([]);
  const [prev, setPrev] = useState();
  const [next, setNext] = useState();
  const [totalResults, setTotalResults] = useState(0);
  const [pageToken, setPageToken] = useState();

  const radialGradient = "radial-gradient(circle, #ff9900, #ff6600)";
  const radialGradient2 = "radial-gradient(circle, #ffefba, #ffffff);";

  const handleFetchData = async (pageToken) => {
    try {
      // const data = await fetch("http://localhost:4000/videos");
      // const data = await fetch("https://bssa-database.vercel.app/videos");
      const resultsPerPage = 12;
      const info_resp = await fetch(
        `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelID}&key=${API_KEY}`
      );
      const info_data = await info_resp.json();
      const uploadID =
        info_data.items[0].contentDetails.relatedPlaylists.uploads;
      const video_resp = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&pageToken=${
          pageToken ?? ""
        }&maxResults=${resultsPerPage}&playlistId=${uploadID}&key=${API_KEY}`
      );
      const video_data = await video_resp.json();
      const nextPageToken = video_data?.nextPageToken;
      const prevPageToken = video_data?.prevPageToken;
      const totalResults = video_data?.pageInfo?.totalResults;
      setTotalResults(totalResults);
      setNext(nextPageToken);
      setPrev(prevPageToken);
      const video_list = video_data.items
        .map((v) => v.snippet)
        .map((v) => {
          return {
            key: v.position,
            eventName: v.title,
            video: `https://youtube.com/embed/${v.resourceId.videoId}`,
          };
        });
      // alert(uploadID);

      // const data = await api.fetchData("/videos");
      const data = video_list;
      // adjust obtained data in the form: {key, eventName, video}
      setData(data);
      // const res = await data.json();
      // setData(res);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData(pageToken);
  }, [pageToken]);
  console.log(data, "data");

  const verticalLineStyle = {
    border: "none",
    borderLeft: "2px solid black",
    height: "100px", // Adjust as needed
    margin: "0 10px", // Adjust spacing
    transform: "rotate(90deg)",
    color: "black",
  };

  // console.log("video", data);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Videos - Bihar State Sports Authority</title>
        <meta name="description" content="Watch videos of sports events, training sessions, and initiatives by the Bihar State Sports Authority." />
      </Helmet>

      <Breadcrumb
        pageName="Videos"
        pageTitle="Videos"
        id="#videos"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <div
        style={{
          background:
            "linear-gradient(to bottom right, #ffffff, #f0f0f0, #87CEFA, #B0E0E6)",
        }}
      >
        {/*  <Container w='100%' align='center' m="auto"  border="2 gray.500"> */}
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize={["18", "42"]}
          letterSpacing="wide"
          color="teal.800"
          px={[4, 10, 20]}
          py={[2, 5, 10]}
        >
          Videos
        </Text>

        {/* ------------------------------------horizontal Line---------------------------------------------------- */}
        <div
          style={{
            height: "4px",
            width: "100%",
            backgroundColor: " rgb(8, 44, 44)",
            paddingBottom: "3px",
          }}
        ></div>
        {/* ------------------------------------------------------------------------------------------------------- */}

        {data && !!data?.length && (
          <div>
            <p>
              <b>{data[0]?.key + 1}</b> -{" "}
              <b>{(data?.[data?.length - 1]?.key ?? 0) + 1}</b> of{" "}
              <b>{totalResults}</b>{" "}
            </p>
          </div>
        )}

        <Center
          position="relative"
          alignContent={"center"}
          alignItems={"center"}
          pt={"20px"}
          pb={"50px"}
        >
          <Grid
            templateColumns={[
              "1fr",
              "1fr 1fr",
              "1fr 1fr 1fr",
              "1fr 1fr 1fr 1fr",
            ]}
            alignItems={"center"}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                pt="10%"
                // pl={"16%"}
                p="5"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.1)" }}
              >
                <motion.div
                  key={index}
                  initial={{
                    opacity: 0,
                    translateX: index % 2 === 0 ? -50 : 50,
                    translateY: -50,
                  }}
                  whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.2 }}
                >
                  {/*---------------------------- This video will have equal sides--------------------- */}

                  <AspectRatio maxW="560px" ratio={1} bg={"pink"}>
                    <iframe title="naruto" src={item?.video} allowFullScreen />
                  </AspectRatio>

                  <Text>{item?.eventName}</Text>
                </motion.div>
              </Box>
            ))}
          </Grid>
        </Center>
        {data && !!data?.length && (
          <div className="m-5">
            <p>
              <b>{data[0]?.key + 1}</b> -{" "}
              <b>{(data?.[data?.length - 1]?.key ?? 0) + 1}</b> of{" "}
              <b>{totalResults}</b>{" "}
            </p>
          </div>
        )}
        <div className="row justify-content-between">
          {prev && (
            <button
              style={{
                backgroundColor: "yellow",
                padding: "1%",
                borderRadius: 10,
                marginRight: "10px",
              }}
              onClick={() => {
                setPageToken(prev);
              }}
            >
              <ChevronLeftIcon boxSize={16} />
            </button>
          )}
          {next && (
            <button
              style={{
                backgroundColor: "orange",
                padding: "1%",
                borderRadius: 10,
              }}
              onClick={() => {
                setPageToken(next);
              }}
            >
              <ChevronRightIcon boxSize={16} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
