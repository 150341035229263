import React, { useEffect, useState } from "react";
import api from "../utils/ApiMethod";

const Banner = () => {
  const [bannerList, setBannerList] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("get/banner");
      setBannerList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <section className="bannerSection">
        <div
          id="bannerSection-Carousel"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators" style={{position:"absolute",bottom:"2%"}}>
            {bannerList &&
              bannerList.map((elem, index) => (
                <li
                  key={elem._id}
                  data-target="#bannerSection-Carousel"
                  data-slide-to={index}
                  className={`${index === 0 ? "active" : ""}`}
                ></li>
              ))}
          </ol>

          <div className="carousel-inner">
            {bannerList &&
              bannerList.map((elem, index) => (
                <div
                  className={`item ${index === 0 ? "active" : ""}`}
                  key={elem?._id}
                >
                  <img
                    src={`https://api.biharsports.org/${elem?.mobileBannerUrl?.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.bannerTitle}
                    className="mob__banner"
                  />

                  <img
                    src={`https://api.biharsports.org/${elem?.desktopBannerUrl?.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.bannerTitle}
                    className="other__banner"
                  />
                  <div className="container">
                    <div className="bannercarousel carousel-caption">
                      <div className="carousel-caption__area animated bounceInRight slower">
                        {/* Your caption content */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
        {/* </div> */}
      </section>
    </>
  );
};
export default Banner;
