import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from '../styles/gallery.module.css'

// import Image from 'next/image'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  VStack,
  Avatar,
  GridItem,
  Grid,
  Image,
  useColorModeValue,
  SimpleGrid,
  // keyframes,
  background,
} from "@chakra-ui/react";
import {keyframes} from '@emotion/react'

const animation = keyframes`
from {
 background:inherit
}
to{
    background:gray.100
}
`;



const Gallery = () => {


  const [galleryPageData, setGalleryPageData] = useState([]);
  const myAnimation = `${animation} infinite 1s`;


  const containerVariants = {
    hover: {
      initial: {
        opacity: .2,
      },
      animate: {
        opacity: 1,
      }




      // scale: 1.1,
      // boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
      // transition: {
      //   duration: 0.3, // Adjust the duration as needed
      //   ease: 'easeInOut', // Use a different easing function if desired
      // },
    },
  }



  const imageVariants = {
    hover: {
      border: '2px solid #ff9900', // Add a border on hover
    },
    initial: {
      border: '2px solid transparent', // Initial border
    },
  };



  const boxVariants = {
    initial: { border: '2px solid transparent' }, // Initial state without border
    hover: { border: '2px solid #ff9900' }, // Border on hover
  };
  // const handleFetchData = async () => {
  //   try {
  //     const data = await api.fetchData("/get/gallery");
  //     setGalleryPageData(data);
  //     console.log("Fetched data:", data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  return (
    <>
      {/* <section class="gallerySection ">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-8">
              <h4 class="section-heading">
                गैलरी
                <a
                  href="/gallery"
                  class="btn btn-default button-link pull-right"
                >
                  आगे देखें <i class="bi bi-arrow-up-right-circle"></i>
                </a>
              </h4>
              <div
                class="grid-container gallerySection-container"
                id="lightgallery"
              > */}
      {/*  <a href="img/gallery/gallery-1.jpg" class="grid-item"
                data-sub-html="FELICITATION OF KHELO INDIA YOUTH GAMES 2022 MEDAL WINNERS">
                <figure><img src="img/gallery/gallery-1.jpg" alt="khelo-india" /></figure>
              </a> */}

      {/* <a
                  href="img/gallery/gallery-new-img-1.jpg"
                  class="grid-item"
                  data-sub-html="KHEL SAMMAN SAMAROH"
                >
                  <figure>
                    <img
                      src="img/gallery/gallery-new-img-1.jpg"
                      alt="Khel-Samman-Samaroh"
                    />
                  </figure>
                </a>

                <a
                  href="img/gallery/gallery-new-img-2.jpg"
                  class="grid-item"
                  data-sub-html="KHEL SAMMAN SAMAROH-2"
                >
                  <figure>
                    <img
                      src="img/gallery/gallery-new-img-2.jpg"
                      alt="Khel-Samman-Samaroh-2"
                    />
                  </figure>
                </a>


                <a
                  href="img/gallery/gallery-new-img-3.jpg"
                  class="grid-item"
                  data-sub-html="KHEL SAMMAN SAMAROH-3"
                >
                  <figure>
                    <img
                      src="img/gallery/gallery-new-img-3.jpg"
                      alt="Khel-Samman-Samaroh-3"
                    />
                  </figure>
                </a>

                <a
                  href="img/gallery/gallery-new-img-4.jpg"
                  class="grid-item"
                  data-sub-html="KHEL SAMMAN SAMAROH-4"
                >
                  <figure>
                    <img
                      src="img/gallery/gallery-new-img-4.jpg"
                      alt="Khel-Samman-Samaroh-4"
                    />
                  </figure>
                </a>

                <a
                  href="img/gallery/gallery-new-img-5.jpg"
                  class="grid-item"
                  data-sub-html="KHEL SAMMAN SAMAROH-5"
                >
                  <figure>
                    <img
                      src="img/gallery/gallery-new-img-5.jpg"
                      alt="Khel-Samman-Samaroh-5"
                    />
                  </figure>

                </a>

                <a
                  href="img/gallery/gallery-new-img-6.jpg"
                  class="grid-item"
                  data-sub-html="KHEL SAMMAN SAMAROH-6"
                >
                  <figure>
                    <img
                      src="img/gallery/gallery-new-img-6.jpg"
                      alt="Khel-Samman-Samaroh-6"
                    />
                  </figure>
                </a> */}

      {/*<a href="img/gallery/khelo-university-odisha-big.jpeg" class="grid-item" data-sub-html="KHELO INDIA UNIVERSITY GAMES ODISHA 2020">
                        <figure><img src="img/gallery/khelo-university-odisha.jpg" alt="khelo-india" /></figure>
                     </a>


                     <a href="img/gallery/khelo-india-inauguration-big.jpeg" class="grid-item" data-sub-html="KHELO INDIA UNIVERSITY GAMES ODISHA 2020">
                        <figure><img src="img/gallery/khelo-india-inauguration.jpg" alt="khelo-india" /></figure>
                     </a>

                     <a href="img/gallery/khelo-india-university-game-big.jpeg" class="grid-item" data-sub-html="KHELO INDIA UNIVERSITY GAMES ODISHA 2020">
                        <figure><img src="img/gallery/khelo-india-university-game.jpg" alt="khelo-india" /></figure>
                     </a>
                     <a href="img/gallery/khelo-india-odisha-game-big.jpeg" class="grid-item" data-sub-html="KHELO INDIA UNIVERSITY GAMES ODISHA 2020">
                        <figure><img src="img/gallery/khelo-india-odisha-game.jpg" alt="khelo-india" /></figure>
                     </a>
                     <a href="img/gallery/common-wealth-table-tennis-big.jpeg" class="grid-item" data-sub-html="21st Commonwealth Table Tennis Championship 2019 Odisha">
                        <figure><img src="img/gallery/common-wealth-table-tennis.jpg" alt="21st Commonwealth Table Tennis Championship 2019 Odisha" /></figure>
                     </a>
                     <a href="img/gallery/common-wealth-tennis-championship-big.jpeg" class="grid-item" data-sub-html="21st Commonwealth Table Tennis Championship 2019 Odisha">
                        <figure><img src="img/gallery/common-wealth-tennis-championship.jpg" alt="21st Commonwealth Table Tennis Championship 2019 Odisha" /></figure>
                     </a> */}

      {/*   <a href="img/gallery/kalpana-dash-mortal-big.jpg" class="grid-item" data-sub-html="Kalpana Dash's mortal remains reaches Bhubaneswar">
                        <figure><img src="img/gallery/kalpana-dash-mortal.jpg" alt="Kalpana Dash's mortal remains reaches Bhubaneswar" /></figure>
                     </a>
                     <a href="img/gallery/minister-visits-stadium-big.jpg" class="grid-item" data-sub-html="Sports Minister visits Kalinga Stadium Complex">
                        <figure><img src="img/gallery/minister-visits-stadium.jpg" alt="Sports Minister visits Kalinga Stadium Complex" /></figure>
                     </a> */}


      {/* </div>
            </div> */}
      {/* <div class="col-sm-12 col-md-4 col-lg-4 achiever-card" style={{}}>
              <div class="youthServices" style={{ marginBottom: "2rem" }}>
                <h4 class="youthServices__heading">हमारी उपलब्धियां </h4>
                <ul class="youthServices__content">
                  <li>
                    <a
                      href="#"
                      title="Odisha State Youth Policy-2013"
                      class="youthServices__content__links"
                    >
                      19 वें एशियन गेम्स के लिए देश की महिला रग्बी 7s टीम के
                      प्रशिक्षण शिविर में बिहार की 5 बेटियों श्वेता शाही, कविता
                      कुमारी, सपना कुमारी, अर्चना कुमारी एवम् धर्मशीला कुमारी का
                      चयन हुआ है।
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      title="Youth Awards"
                      class="youthServices__content__links"
                    >
                      बॉबी कुमार विश्व सेपक टाकरा चैंपियनशिप के लिए चयनित।
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      title="Promotions of Adventure Activities"
                      class="youthServices__content__links"
                    >
                      खुशी कुमारी एशियन फुटबॉल लीग के लिए चयनित।
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      title="Integrated Youth Development Programme"
                      class="youthServices__content__links"
                    >
                      क़ैसर यूथ एशियन एथलेटिक्स चैम्पियनशिप के लिए चयनित।
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
      {/* </div>
        </div>
      </section> */}

      {/* <div> */}
      <div style={{ width: "95%", margin: "40px auto"}} className={styles.main}>


      <Text
        fontWeight="bold"
        textTransform="uppercase"
        fontSize={["18", "42"]}
        letterSpacing="wide"
        color="teal.800"
        px={[4, 10, 20]}
        m={4}
        p={4}
        _hover={{
          animation: myAnimation,
        }}
      >
        गेलरी
      </Text>

        <SimpleGrid
          columns={[1, 2, 3, 4]}
          spacing="20px"
          m="auto"
          width="90%"

        >
          {/* image section  */}

          {/* first image */}
          <motion.Box height="100%" width="100%"

            variants={containerVariants}
            whileHover={{ border: '2px solid black' }}
            mt={-6}
            mx={-6}
            mb={6}

            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
            className='card'
          >
            <a href="img/gallery/gallery-1.jpg" className="grid-item"
              data-sub-html="FELICITATION OF KHELO INDIA YOUTH GAMES 2022 MEDAL WINNERS">
              {/* <figure> */}
                <img src="img/gallery/gallery-1.jpg" alt="khelo-india"
                className={styles.card}
                />
                {/* </figure> */}
            </a>


          </motion.Box>
{/* --------------------------------------- */}



          <motion.Box height="100%" width="100%"
            mt={-6}
            mx={-6}
            mb={6}
            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
          >

            <a
              href="img/gallery/gallery-new-img-1.jpg"
              className="grid-item"
              data-sub-html="KHEL SAMMAN SAMAROH"
            >
              {/* <figure> */}
                <img
                  src="img/gallery/gallery-new-img-1.jpg"
                  alt="Khel-Samman-Samaroh"
                  // className={styles.card}
                  className={styles.card}

                />
              {/* </figure> */}
            </a>


          </motion.Box>


          <motion.Box height="320px" width="100%"
            mt={-6}
            mx={-6}
            mb={6}
            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
          >
            <a
              href="img/gallery/gallery-new-img-2.jpg"
              className="grid-item"
              data-sub-html="KHEL SAMMAN SAMAROH-2"
            >
              {/* <figure> */}
                <img
                  src="img/gallery/gallery-new-img-2.jpg"
                  alt="Khel-Samman-Samaroh-2"
                  className={styles.card}

                />
              {/* </figure> */}
            </a>
          </motion.Box>


          <motion.Box height="320px" width="100%"
            mt={-6}
            mx={-6}
            mb={6}
            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
          >
            <a
              href="img/gallery/gallery-new-img-3.jpg"
              className="grid-item"
              data-sub-html="KHEL SAMMAN SAMAROH-3"
            >
              {/* <figure> */}
                <img
                  src="img/gallery/gallery-new-img-3.jpg"
                  alt="Khel-Samman-Samaroh-3"
                  className={styles.card}

                />
              {/* </figure> */}
            </a>
          </motion.Box>



          <motion.Box height="320px" width="100%"
            mt={-6}
            mx={-6}
            mb={6}
            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
          >
            <a
              href="img/gallery/gallery-new-img-4.jpg"
              className="grid-item"
              data-sub-html="KHEL SAMMAN SAMAROH-4"
            >
              {/* <figure> */}
                <img
                  src="img/gallery/gallery-new-img-4.jpg"
                  alt="Khel-Samman-Samaroh-4"
                  className={styles.card}

                />
              {/* </figure> */}
            </a>



          </motion.Box>


          <motion.Box height="320px" width="100%"
            mt={-6}
            mx={-6}
            mb={6}
            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
          >
            <a
              href="img/gallery/gallery-new-img-5.jpg"
              className="grid-item"
              data-sub-html="KHEL SAMMAN SAMAROH-5"
            >
              {/* <figure> */}
                <img
                  src="img/gallery/gallery-new-img-5.jpg"
                  alt="Khel-Samman-Samaroh-5"
                  className={styles.card}

                />
              {/* </figure> */}
            </a>


          </motion.Box>

          <motion.Box height="320px" width="100%"
            mt={-6}
            mx={-6}
            mb={6}
            pos={"relative"}
            animate={{
              opacity: 1,
            }}
            // before animate the design is
            initial={{
              opacity: 0.1,
            }}
            transition={{
              type: "spring",
              // stiffness:100,
              damping: 100,
            }}
          >
            <a
              href="img/gallery/gallery-new-img-6.jpg"
              className="grid-item"
              data-sub-html="KHEL SAMMAN SAMAROH-6"
            >
              {/* <figure> */}
                <img
                  src="img/gallery/gallery-new-img-6.jpg"
                  alt="Khel-Samman-Samaroh-6"
                  className={styles.card}

                />
              {/* </figure> */}
            </a>

          </motion.Box>






        </SimpleGrid>
        {/* </div> */}
      </div>


    </>
  );
};
export default Gallery;
