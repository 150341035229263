import React from "react";
import Search from "../assets/images/search.png";
import styles from "../styles/menu.module.css";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  Portal,
  Image,
} from "@chakra-ui/react";

const Menu = () => {
  const customStyle = {
    "--i": ".85s", // Convert the CSS custom property to an object property
  };

  const customStyle1 = {
    "--i": ".1.1s", // Convert the CSS custom property to an object property
  };

  const customStyle2 = {
    "--i": "1.35s", // Convert the CSS custom property to an object property
  };

  const customStyle3 = {
    "--i": "1.8s", // Convert the CSS custom property to an object property
  };

  return (
    <div>
      <header>
        <div className={styles.container}>
          <input type="checkbox" name="" className={styles.check} />

          {/* <div className={styles.logo_container}>
            <h3 className={styles.logo}>
              BSSA<span>BIHAR </span>
            </h3>
          </div> */}

          <div className={styles.nav_btn}>
            <div className={styles.nav_links}>
              <ul>
                {/*--------------------- home start---------------------- */}
                <li className={styles.nav_link} style={customStyle}>
                  <a href="/" title="home">
                    <span>
                      {/* <i className="fas fa-home"></i> */}
                      <i class="bi bi-house"></i>
                    </span>
                  </a>
                </li>

                {/*--------------------- home ends---------------------- */}

                {/* ------------------------about us section ----------------- starts     */}
                <li className={styles.nav_link} style={customStyle}>
                  <a>
                    About us<i className="bi bi-chevron-down"></i>
                  </a>

                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/aboutus" title="introduction">
                          Introduction
                        </a>
                      </li>
                      {/*
                      <li className={styles.dropdown_link}>
                        <a href="/" title="Sports legacy">
                          Our Sports Legacy
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="/visionandmission" title="Vision and Mission">
                          Mission, Vision and Objectives
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/sportlegecy" title="Sport Legecy">
                          Our Sporting Heritage{" "}
                        </a>
                      </li>
                      {/* --------------multilevel drop down------------ for sendesh */}

                      <li className={styles.dropdown_link}>
                        <a>
                          Message <i className="bi bi-chevron-down"></i>
                        </a>
                        <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            {/* cm */}
                            <li className={styles.dropdown_link}>
                              <a href="/chiefminister" title="Chief Minister">
                                {" "}
                                Honorable Chief Minister, Bihar
                              </a>
                            </li>

                            {/*            v cm   */}
                            <li className={styles.dropdown_link}>
                              <a
                                href="/deputychiefminister"
                                title="Deputy Chief Minister"
                              >
                                {" "}
                                Honorable Deputy Chief Minister, Bihar
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/sports-minister"
                                title="Hon'ble Minister, Department of Art, Culture and Youth, Government of Bihar."
                              >
                                {" "}
                                Hon’ble Sports Minister, Government of Bihar
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/principal-secretary-sports-department"
                                title="Additional Chief Secretary, Department of Art, Culture and Youth, Government of Bihar."
                              >
                                Principal Secretary, Sports Department
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/ravindran"
                                title="Director General cum CEO Bihar State Sports Authority"
                              >
                                Director General cum Chief Executive Officer,
                                Bihar State Sports Authority{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/directorcumSecretaryBSSA"
                                title="Director cum Secretary Bihar State Sports Authority"
                              >
                                Director cum Secretary, Bihar State Sports
                                Authority
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      {/*------------------------------ hello----------------------------- */}

                      {/*-----------------------helloooo- -----------------------  */}
                      {/* <li className={styles.dropdown_link}>
                        <a href="#">
                          Link 3<i className="bi bi-chevron-down"></i>
                        </a>
                        <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            <li className={styles.dropdown_link}>
                              <a href="#">Link 1</a>
                            </li>
                            <li className={styles.dropdown_link}>
                              <a href="#">Link 2</a>
                            </li>
                            <li className={styles.dropdown_link}>
                              <a href="#">Link 3</a>
                            </li>
                            <li className={styles.dropdown_link}>
                              <a href="#">
                                More<i className="bi bi-chevron-down"></i>
                              </a>
                              <div
                                className={`${styles.dropdown} ${styles.second}`}
                              >
                                <ul>
                                  <li className={styles.dropdown_link}>
                                    <a href="#">Link 1</a>
                                  </li>
                                  <li className={styles.dropdown_link}>
                                    <a href="#">Link 2</a>
                                  </li>
                                  <li className={styles.dropdown_link}>
                                    <a href="#">Link 3</a>
                                  </li>
                                  <div className={styles.arrow}></div>
                                </ul>
                              </div>
                            </li>
                            <div className={styles.arrow}></div>
                          </ul>
                        </div>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a
                          href="/organizationalStru"
                          title="Organizational Structure"
                        >
                          Organizational Structure
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/prioritysports" title="priority sports">
                          {" "}
                          Priority Sports of Bihar
                        </a>
                      </li>

                      {/* <li className={styles.dropdown_link}>
                        <a href="/sports-minister" title="Jitendra sir">
                          {" "}
                          श्री जितेंद्र कुमार राय (माननीय मंत्री, कला,संस्कृति
                          एवं युवा विभाग )
                        </a>
                      </li> */}

                      {/* <li className={styles.dropdown_link}>
                        <a href="/principal-secretary-sports-department" title="District Sports Officers">
                          श्रीमती हरजोत कौर बम्हरा भा. प्र. से. अपर मुख्य सचिव
                          (कला,संस्कृति एवं युवा विभाग )
                        </a>
                      </li> */}

                      {/* <li className={styles.dropdown_link}>
                        <a href="/ravindran" title="Ravindran sir">
                          {" "}
                          श्री रविन्द्रण शंकरण भा. पु. से. महानिदेशक सह मुख्य
                          कार्यकारी अधिकारी (बिहार राज्य खेल प्राधिकरण ){" "}
                        </a>
                      </li> */}

                      {/* <li className={styles.dropdown_link}>
                        <a href="/directorcumSecretaryBSSA" title="Pankaj sir">
                          {" "}
                          श्री पंकज राज भा. पु. से. निदेशक सह सचिव (बिहार राज्य
                          खेल प्राधिकरण )
                        </a>
                      </li> */}

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="Policies and Schemes">
                    Policies and Schemes <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/policy" title="Our Policies">
                          {" "}
                          हमारी नीतियां
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="img/BSSA_Bye_Laws.pdf" target="_blank">
                          {" "}
                          Sports Rules and Regulations
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="img/Sport_Rule_2023.pdf" target="_blank">
                          {" "}
                          Sports Policy
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/sportsreqrmtpolicy"
                          title="Sports Recruitment Policy"
                        >
                          {" "}
                          Sports Recruitment Policy
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/kss" title="Khel Samman samaroh">
                          Khel Samman Samaroh
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/sportswelfarefund"
                          title="Sports Welfare Fund"
                        >
                          Players Welfare Fund
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/grantssportsfederations"
                          title="grants to sports federations"
                        >
                          Grants to Sports Associations
                        </a>
                      </li>

                      {/* -----------------------  Comment for temporary ------------------ */}
                      {/* <li className={styles.dropdown_link}>
                        <a
                          href="/sportequiinf"
                          title="Sports-insrtruments-information"
                        >
                          खेल उपकरण संबंधी जानकारी
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="/sportsscholarshippolicy" title="upcoming policies">
                          Sports Scholarship Policy{" "}
                          {/* <i className="bi bi-chevron-down"></i> */}
                        </a>
                        {/* <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            <li className={styles.dropdown_link}>
                              <a
                                href="/sportsscholarshippolicy"
                                title="Sports Scholarship Policy"
                              >
                                {" "}
                                Sports Scholarship Policy{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/inspiration" title="Prerna">
                                Prerna{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/able" title="Saksham">
                                {" "}
                                Saksham
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                Udaan
                              </a>
                            </li>

                            
                          </ul>
                        </div> */}
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="our achievements">
                    Our Achievements <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a
                          href="/internationalachievement"
                          title="international achievement"
                        >
                          {" "}
                          International Achievements
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a
                          href="/nationalachievement"
                          title="national achievement"
                        >
                          National Achievements
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/statelevelachievement"
                          title="State level achievements"
                        >
                          State level Achievements
                        </a>
                      </li>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/kheloindiacenter" title="Khelo India Center">
                          खेलो इंडिया केंद्र
                        </a>
                      </li> */}

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                {/* --------------- खेल संस्थाएं ------------- */}
                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="our achievements">
                    Sports Organizations <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="img/eklavya_Kendra.pdf" target="_blank">
                          {" "}
                          Eklavya Center
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="img/Kic.pdf" target="_blank">
                          {" "}
                          Khelo India Center{" "}
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="img/khel_bhawan_summary_new .pdf"
                          target="_blank"
                        >
                          {" "}
                          Sports Building
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="img/Training_Center.pdf" target="_blank">
                          {" "}
                          Training Center
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>
                {/*99999999999999999999999999999 */}

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="/tenders" title="tenders">
                    Tenders
                  </a>
                  {/* <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/tenders" title="Tenders">
                          निविदाएं
                        </a>
                      </li>


                  {/* <li className={styles.dropdown_link}>
                        <a href="/recruitmentnotices" title="33 Khelo">
                          {" "}
                          बिहार में 33 खेलो इंडिया केंद्रों के लिए प्रशिक्षकों
                          की नियुक्ति संबंधी सूचनाएं - 3 जुलाई
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a
                          href="/registrationforkss"
                          title="Resistration for Khel Samman Samaroh"
                        >
                          खेल सम्मान समारोह 2023 के लिए पंजीकरण
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/policy" title="Our Policies">
                          मेडल लाओ नौकरी पाओ
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/policy" title="Our Policies">
                          मेडल लाओ नौकरी पाओ
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/sportsbuget" title="Sports budget">
                          खेल बजट
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="#" title="Our Policies">
                          खेल संबंधी आधारभूत संरचनाएं
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/upcommingevents" title="Upcomming events">
                          आगामी आयोजन
                        </a>
                      </li> */}

                  {/* <div className={styles.arrow}></div>
                    </ul>
                  </div> */}
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="gallery">
                    Gallery <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/gallery" title="Events Gallery">
                          आयोजनों की प्रस्तुति
                        </a>
                      </li> */}
                      <li className={styles.dropdown_link}>
                        <a href="/GalleryPage" title="photos">
                          Photos
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/videos" title="videos">
                          Videos
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="/#" title="events and programs">
                    Events and Programs
                    <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/eventorg" title="upcoming policies">
                          Past Events
                        </a>
                        {/* <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            <li className={styles.dropdown_link}>
                              <a
                                href="/sportsscholarshippolicy"
                                title="Sports Scholarship Policy"
                              >
                                {" "}
                                स्पोर्ट्स कॉन्क्लेव{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/inspiration" title="Prerna">
                                स्पोर्ट्स कॉन्क्लेव 2.0{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/able" title="Saksham">
                                {" "}
                                खेल सम्मान समारोह <i className="bi bi-chevron-down"></i>
                              </a>
                              <div className={`${styles.dropdown} ${styles.second}`}>
                                <ul>

                                <li className={styles.dropdown_link}>
                                    <a href="/kss2023" title="Udaan">
                                      {" "}
                                      खेल सम्मान समारोह 2023

                                    </a>
                                  </li>

                                  <li className={styles.dropdown_link}>
                                    <a href="/kss2022" title="Saksham">
                                      {" "}
                                      खेल सम्मान समारोह 2022
                                    </a>
                                  </li>
                                </ul>
                              </div>

                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                राजा कर्ण तीरंदाजी  (लक्ष्य)

                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                दक्ष

                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                तरंग
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                नीडजैम 2023

                              </a>
                            </li>

                          </ul>
                        </div> */}
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/upcomingevents" title="">
                          Upcoming Events
                        </a>
                      </li>

                      {/* <li className={styles.dropdown_link}>
                        <a href="/" title="">
                          शीघ्र प्रारंभ होने वाली खेल नीतियाँ
                        </a>
                      </li> */}
                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="download">
                    Downloads <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/notices" title="Notices & Circulars">
                          {" "}
                          Notifications
                        </a>
                      </li>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/press-release" title="Press release">
                          Press Release
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="/advertisements" title="Advertisement">
                          Advertisement
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a href="/annual-calender" title="annual calendar">
                          Annual Calendar
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/annual-report" title="annual report">
                          Annual Report
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/cabinet-decision" title="cabinet decision">
                          Cabinet Decisions
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle}>
                  <a
                    href="/careerandopportunity"
                    title="career and opportunity"
                  >
                    Career
                  </a>
                </li>

                {/* <li className={styles.nav_link} style={customStyle2}>
                  <a title="Our Contact">
                    Contact Us<i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/directory" title="directory">
                          {" "}
                          Directory
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a href="/contactus" title="Leave Message/Question">
                          {" "}
                          Message/Ask Question
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li> */}

                <li className={styles.nav_link} style={customStyle}>
                  <a href="/dsodirectory" title="DSO Directory">
                    DSO Directory
                  </a>
                </li>

                {/* ------------------------- search item----------------- */}
                <li className={styles.nav_link} style={customStyle}>
                  <a>
                    <Popover>
                      <PopoverTrigger>
                        <div className={styles.search}>
                          <img
                            src={Search}
                            style={{
                              height: "20px",
                              color: "white",
                            }}
                          />
                        </div>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverArrow />

                          <PopoverBody>
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search"
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  </a>
                </li>
              </ul>

              {/*
              <li className={styles.nav_link} style={customStyle2}>
                  <a href="/tenders">
                  {SearchIcon}
                  </a>
                </li> */}
            </div>

            {/* <div className={styles.log_sign} style={customStyle3}>
              <a href="#" className={`${styles.btn} ${styles.transparent}`}>
                Log in
              </a>
              <a href="#" className={`${styles.btn} ${styles.solid}`}>
                Sign up
              </a>
            </div> */}
          </div>

          <div className={styles.hamburger_menu_container}>
            <div className={styles.hamburger_menu}>
              <div></div>
            </div>
          </div>
        </div>
      </header>

      {/* <main>
        <section>
          <div className="overlay"></div>
        </section>
      </main> */}
    </div>
  );
};

export default Menu;
