export const CabinetDecisionsData = [
  {
    title:
      "Approval for creation of 33 contract based posts of various categories for smooth operation of the under construction State Sports Academy and International Standard Cricket Stadium at Rajgir under Nalanda district",
    file: "pdf/cabinet-decision/33 Post sanlekh.pdf",
  },
  {
    title:
      " Regarding approval for creation of 31 posts of officers and employees for the purpose of administrative work of Bihar Sports University, Rajgir",
    file: "pdf/cabinet-decision/31 Post sanlekh (1).pdf",
  },
  {
    title:
      "Regarding in-principle consent for organizing the proposed Hockey Asian Championship Trophy 2024 at Rajgir from 11-20 November 2024 and consent by Bihar Government to give an amount of Rs. 10.00 crore to Hockey India for the purpose",
    file: "pdf/cabinet-decision/Asian Hockey Champ. Trophoy Sankalp.pdf",
  },
  {
    title:
      "Regarding approval for purchase of total 06 vehicles under the prescribed limit for the use of Honorable Minister and officials of Sports Department.",
    file: "pdf/cabinet-decision/466 Post.pdf",
  },
  {
    title:
      "Approval for creation of 301 posts of various categories at headquarters and regional levels for smooth management and operation of Bihar State Sports Authority",
    file: "pdf/cabinet-decision/301 BSSA.pdf",
  },
];
