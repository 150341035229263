import React, { useState } from "react";
import stylesgfi from "../../pages/SGFI/NationalSchoolGames.module.css"; // Assuming you're using CSS for other styles
import Breadcrumb from "../../components/common/Breadcrumb";
import { Button, Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import {
  Box,
  Text,
  Image,
  Stack,
  Heading,
  SimpleGrid,
  SlideFade,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"; // Assuming Chakra UI for Tabs
import { Flex, VStack, HStack } from "@chakra-ui/react";
import ImageCarousel from "../../components/ImageCarousel";

export default function ISTAFSepakTakraw() {
  const [selectedContent, setSelectedContent] = useState("about");
  const matches = [
    {
      id: 1,
      date: "11 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 2,
      date: "11 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 3,
      date: "11 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 4,
      date: "12 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "THAILAND",
      player1Img: "/img/Mosquto.jpeg",
      player2: "JAPAN",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 5,
      date: "12 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 6,
      date: "12 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 7,
      date: "14 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "KOREA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 8,
      date: "14 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "CHINA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 9,
      date: "14 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "THAILAND",
      player1Img: "/img/Mosquto.jpeg",
      player2: "INDIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 10,
      date: "16 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "MALAYSIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "JAPAN",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 11,
      date: "16 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "KOREA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 12,
      date: "16 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "CHINA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 13,
      date: "17 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "MALAYSIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 14,
      date: "17 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 15,
      date: "17 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "INDIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 16,
      date: "19 November 2024",
      time: "14:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (5th/6th Place)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 17,
      date: "19 November 2024",
      time: "17:00 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Semi-final 1)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 18,
      date: "19 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Semi-final 2)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 19,
      date: "20 November 2024",
      time: "17:00 IST",
      tournament: "Women's Asian Champions Trophy 2024 (3rd/4th Place)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 20,
      date: "20 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Final)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
  ];

  const [selectedMatch, setSelectedMatch] = useState(matches[0]); // Default to the first match

  // Handler to update the selected match
  const handleMatchClick = (match) => {
    setSelectedMatch(match);
  };

  const items = [
    {
      title: "Signature Hockey Stick",
      description:
        "Looking for the perfect gift for a hockey fan or a unique memento to commemorate an unforgettable game? Crafted as a tribute to this prestigious tournament, each stick is beautifully designed with high-quality materials and features autographs from top players participating in the tournament. This unique collector’s item celebrates the spirit of international competition and makes the perfect keepsake for hockey enthusiasts. Whether you're a fan or a collector, this hockey stick will be a prized addition to your collection.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Celebratory T-Shirts",
      description:
        "Take home the spirit of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive Tournament T-Shirts. These stylish, comfortable T-shirts are perfect for fans who want to show their support for their favorite teams and celebrate this prestigious tournament. Available in a range of sizes (S/M/L/XL), these T-shirts feature the tournament logo, organizer logos, and vibrant designs, making them the perfect keepsake for anyone attending the tournament. Grab Yours T-Shirts to Cheer your Team!",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Commemorative Caps",
      description:
        "Celebrate the excitement of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive Tournament Caps! These stylish caps, featuring the official tournament logo, are the perfect accessory for showing your support while keeping cool during the tournament. Whether you're cheering from the stands or strolling around town, these caps make a great keepsake and a fashionable reminder of this international tournament.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Mascot Fridge Magnet",
      description:
        "Take home a piece of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive MASCOT (Fridge Magnet)! Featuring GUDIYA, the official tournament mascot, this fun and colorful magnet is a perfect keepsake to remember the excitement of the tournament. Stick it on your fridge or any magnet support surface and relive the thrilling moments of the tournament every day. It's a great collectible for hockey fans and a charming gift for friends and family.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Tournament Logo Fridge Magnet",
      description:
        "Celebrate the Bihar Women’s Asian Champions Trophy 2024 with an exclusive “Tournament Logo Fridge Magnet”. Featuring the official tournament emblem reflecting a blend of sports and heritage, this sleek and stylish magnet is a perfect way to commemorate this historic event. Add a touch of sports pride to your home or office with this collectible magnet, and keep the memory of the tournament alive every day.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "White Tournament Logo Fridge Magnet",
      description:
        "Take home a piece of the “Bihar Women’s Asian Champions Trophy 2024” with our special White Tournament Logo Fridge Magnet. Featuring a clean and sleek white design with the official tournament logo, reflecting a blend of sports and heritage. Perfect for your fridge or any magnetic surface, this elegant keepsake is a stylish way to commemorate the tournament and support your favorite teams.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Mascot 'Gudiya' Soft Toy",
      description:
        "Bring home the charm of the Bihar Women’s Asian Champions Trophy 2024 with the adorable Gudiya soft toy. Inspired by the tournament’s official mascot, this cuddly soft toy is the perfect keepsake for fans of all ages. Made with soft, high-quality materials, the Gudiya toy is not only a great collector’s item but also a wonderful gift for children and hockey enthusiasts alike.",
      imgSrc: "/img/Mosquto.jpeg",
    },
  ];
  // Content data for the right side
  const contentData = {
    about: (
      <div className="card-container">
        <div className="card-body" style={{ lineHeight: "2" }}>
          <h3 className="card-title" style={{ fontSize: "22px" }}>
            SepakTakraw World Cup being organized in Patna
          </h3>

          <p className="card-text">
            SepakTakraw World Cup 2025 is going to be organized for the first
            time in Patna, the capital of Bihar state of India. This grand
            international sports event will be held from 20 March to 25 March
            2025 at Patliputra Indoor Stadium, Kankarbagh, Patna. Top teams and
            players from 20 countries will participate in this competition,
            including France, India, New Zealand, Vietnam, Italy, Poland, Japan,
            Thailand, Singapore, Sri Lanka, Switzerland, United States, Nepal,
            Brazil, Iran, Chinese Taipei, Myanmar, Malaysia and Indonesia. This
            World Cup will have regu, double and quad events under men's,
            women's and mixed categories, which will show the diversity and team
            spirit of the game.
          </p>
          <br />

          <h3 className="card-title" style={{ fontSize: "22px" }}>
            SepakTakraw: A Unique Sport
          </h3>

          <p className="card-text">
            SepakTakraw is a unique sport, also known as 'kick volleyball'. The
            game is played on a ground similar to a badminton court, where
            players hit the ball with their feet, head, chest and knees. The
            length of the court is 13.40 meters and the width is 6.19 meters.
            The ground is divided into two halves by a line, the thickness of
            which is 2 centimeters. All preparations are in full swing under the
            sports infrastructure and 'Khelo India' initiative by the Bihar
            government and the sports department. This event is an opportunity
            to put not only Bihar but India on the global sports map. Come, be a
            part of this Khel Mahakumbh in Patna and watch the world's best
            SepakTakraw players play.
          </p>
          <br />
        </div>
      </div>
    ),
    logoMascot: (
      <div
        style={{
          lineHeight: "2",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {/* Tournament Logo Section */}
        <div style={{ marginBottom: "30px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>
            Official Logo — "SepakTakraw World Cup 2025"
          </h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            The official logo of the SepakTakraw World Cup 2025 reflects the
            spirit and purpose of this mega event. The logo is a beautiful blend
            of India's historical heritage, modern sporting culture and global
            unity.
          </p>
          <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>
            Features of the logo:
          </h5>

          <Row style={{ marginBottom: "0px" }}>
            <Col md={8}>
              <ul style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>
                  Temple Shape: The logo includes a temple-shaped symbol to pay
                  homage to India's religious and cultural heritage. It is
                  reminiscent of the historical identity of Bihar.
                </li>
                <li style={{ listStyle: "auto" }}>
                  SepakTakraw Ball: The ball in the middle of the logo
                  represents the speed, skill and teamwork of this sport. It
                  shows how players play with balance, agility and strategy.
                </li>
                <li style={{ listStyle: "auto" }}>
                  Bold and Vibrant Colours:
                  <ul>
                    <li style={{ listStyleType: "disc" }}>
                      Purple (Strength and Courage){" "}
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Orange (Excitement and Energy){" "}
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Green (Prosperity and Growth){" "}
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Yellow (Hope and Happiness){" "}
                    </li>
                  </ul>
                </li>
                <li style={{ listStyle: "auto" }}>
                  These colours represent India's diversity and colours of life,
                  which is the soul of the entire event.
                </li>
                <li style={{ listStyle: "auto" }}>
                  Symbol of Global Competition: The overall design of the logo
                  conveys the message that SepakTakraw World Cup 2025 is not
                  just a sporting event but a confluence of culture, sports and
                  international friendship.
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <img
                src="./img/Logo-Sepak-Takraw.png"
                alt="Description of the image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Col>
          </Row>
          <p style={{ textAlign: "justify" }}>
            This logo is not only a matter of pride for sports lovers but also
            establishes India's hospitality and sporting culture on
            the world stage.
          </p>
        </div>

        {/* Mascot Section */}
        <div style={{ lineHeight: "2", marginTop: "50px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>
            Mascot Bhim
          </h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            The official mascot of the SepakTakraw World Cup Bihar 2025, Bhim is
            inspired by the state animal of Bihar, the Bison (Gaur). Bhim not
            only symbolizes strength and resilience, but also represents
            friendly behavior and sportsmanship. Dressed in traditional Bihari
            attire, Bhim reflects the cultural heritage of the state and
            inspires and captivates everyone with his fiery style and
            strong mannerisms
          </p>
          <Row>
            <Col md={8}>
              {/* key symbolism */}
              <h5 style={{ textAlign: "justify" }}>
              BHIM: Symbol of SepakTakraw World Cup 2025


              </h5>
              <br />
              <ul style={{ textAlign: "justify", paddingLeft: "20px" }}>
                <li style={{ listStyleType: "disc" }}>
                <b>The powerhouse:</b> The tremendous strength of the bison inspires powerful kicks in SepakTakraw.

                </li>
                <li style={{ listStyleType: "disc" }}>
                <b>The star of the team:</b> The herd bison is the symbol of teamwork, as coordination is essential in the sport.

                </li>
                <li style={{ listStyleType: "disc" }}>
                <b>Agility and balance:</b> Despite its heavy body, it is fast and balanced—just like the SepakTakraw players.

                </li>
                <li style={{ listStyleType: "disc" }}>
                <b>The master of patience:</b> The calm and tolerant bison teaches that patience leads to victory in difficult shots.

                </li>
                <li style={{ listStyleType: "disc" }}>
                <b>Symbol of Courage:</b> The big-horned Bison is not afraid of challenges, just like the players who kick high.

                </li>
                <li style={{ listStyleType: "disc" }}>
                <b>Champion of Sportsmanship:</b> The herbivorous and friendly Bison represents the true spirit of the game.

                </li>
                <li style={{ listStyleType: "disc" }}>
                <b>King of the Field:</b> Impressive in the jungle, Bhim is all set to infuse excitement at SepakTakraw 2025!
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <img
                src="./img/sepak-takraw-bheem.png"
                alt="Description of the image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Col>
          </Row>
        </div>
      </div>
    ),
    matchFixtures: (
      <div className="card-container">
        <p>
          The SepakTakraw World Cup 2025 is going to be held in Bihar, India
          from 20 to 25 March, which brings an exciting opportunity for sports
          lovers. Organized by the International SepakTakraw Federation (ISTAF),
          this prestigious tournament will bring together top players from
          around the world. It will include various events such as quadrant,
          double, mixed, and regu in men's and women's categories. This is a
          great opportunity for SepakTakraw fans!
        </p>
        <br />
        <p>
          You will find the complete schedule of this tournament on this page in
          which the date, time and match information of matches of various
          categories like quadrant, doubles, regu and mixed events are given.
          Here you can easily check the details of your favorite matches and
          enjoy every exciting moment of the tournament.
        </p>
        <br />
        <p>
          Check the complete details of all the matches in the schedule below
          and get ready to support your favorite teams!
        </p>
        <br />
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={() =>
              window.open(
                // "pdf/events/ISW2025 - Bihar - Competition Schedule as of 19 March 2025 - as of 1300.pdf",
                "pdf/events/ISW2025 - Bihar - Competition Schedule as of 21 March 2025 - as of 1100.pdf",
                "_blank"
              )
            }
          >
            View Competition Schedule
          </Button>
        </div>
        <br />
        <ImageCarousel />
        {/* iframe not working on mobile browser */}
        {/* <div className="d-flex justify-content-center">
          <iframe
            src="pdf/events/ISW2025 - Bihar - Competition Schedule as of 19 March 2025 - as of 1300.pdf"
            style={{ width: "100%", height: "500px", border: "none" }}
            title="Competition Schedule"
          ></iframe>
          </div> */}
      </div>
    ),
    indianTeamDetails: (
      <div>
        <Row>
          <Col md={6}>
            <h1>Women's Squad</h1>
            <div className="d-md-none d-flex justify-content-center"></div>
            <div className="d-none d-md-block">
              {/* <iframe
                src="pdf/events/women-team-India-sepaktakraw.pdf"
                style={{
                  width: "100%",
                  height: "500px",
                  border: "none",
                  overflow: "hidden",
                }}
                title="Women Team India"
              ></iframe> */}
              <img
                src={"img/team-india-women-sepaktakraw.jpg"}
                alt="Women's Team"
                style={{ height: "auto", width: "100%", objectFit: "contain" }}
              />
            </div>
          </Col>
          <Col md={6}>
            <h1>Men's Squad</h1>
            <div className="d-md-none d-flex justify-content-center"></div>
            <div className="d-none d-md-block">
              {/* <iframe
                src="pdf/events/team-India-men-sepaktakraw.pdf"
                style={{
                  width: "100%",
                  height: "500px",
                  border: "none",
                  overflow: "hidden",
                }}
                title="Men Team India"
              ></iframe> */}
              <img
                src={"img/team-india-men-sepaktakraw.jpg"}
                alt="Women's Team"
                style={{ height: "auto", width: "100%", objectFit: "contain" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    ),
    results:(
      <ImageCarousel data={
        [
          {
            imageURL: "img/istaf-sepaktakraw-result-1.jpg",
            title: "result 1",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-2.jpg",
            title: "result 2",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-3.jpg",
            title: "result 3",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-4.jpg",
            title: "result 4",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-8.jpg",
            title: "result 5",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-7.jpg",
            title: "result 6",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-6.jpg",
            title: "result 7",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-5.jpg",
            title: "result 8",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-9.jpg",
            title: "result 9",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-10.jpg",
            title: "result 10",
          },
        ]
      }/>
    )
  };

  const formatKey = (key) => {
    // Define a mapping for specific keys to their corresponding string values
    const keyMapping = {
      about: "About",
      logoMascot: "Logo & Mascot",
      matchFixtures: "Match Fixtures",
      indianTeamDetails: "Indian Team Details",
      results: "Results",
    };

    // Return the mapped value if it exists, otherwise format the key
    return keyMapping[key];
  };

  return (
    <>
      <Breadcrumb
        pageName={ISTAFSepakTakraw}
        pageTitle={"ISTAF Sepak Takraw 2025"}
        img_url={"./img/istaf-sepak-takraw-2025-desktop.jpg"}
        mobile_banner="./img/istaf-sepak-takraw-2025-mobile.jpg"
      />
      <Container style={{ marginTop: "30px", marginBottom: "30px" }}>
        <div
          className={`container`}
          style={{
            borderRadius: "8px",
            marginTop: "15px",
            marginBottom: "5px",
          }}
        >
          <Tabs
            isManual
            variant="enclosed"
            onChange={(index) => {
              // Map the index to the corresponding content key
              const selectedKey = Object.keys(contentData)[index];
              setSelectedContent(selectedKey);
            }}
          >
            {/* Menu Tabs */}
            <TabList
              style={{
                display: "flex",
                flexWrap: "wrap", // Allow items to wrap to the next row
                gap: "5px", // Optional: Add spacing between tabs
              }}
            >
              {Object.keys(contentData).map((key, index) => (
                <Tab
                  key={key}
                  className="tabb"
                  as="b"
                  style={{
                    backgroundColor:
                      selectedContent === key ? "rgb(49 62 69)" : "#24528e", // Change background if active
                    color: "white",
                    padding: "5px 5px",
                    cursor: "pointer",
                    marginBottom: "20px",
                    // Responsive font size
                    fontSize: "12px", // Default for medium screen (md)
                  }}
                >
                  {formatKey(key)}
                </Tab>
              ))}
            </TabList>

            {/* Content Panels */}
            <TabPanels
              style={{
                borderLeft: "1px solid #cfd9de",
                borderRight: "1px solid #cfd9de",
                borderBottom: "1px solid #cfd9de",
                borderRadius: "0px 0px 5px 5px",
              }}
            >
              {Object.keys(contentData).map((key) => (
                <TabPanel key={key}>
                  {/* Render the content based on selected tab */}
                  {contentData[key]}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      </Container>
    </>
  );
}
