import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/common/Breadcrumb";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import api from "../../utils/ApiMethod";

import { motion } from "framer-motion";

// import Image from 'next/image'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  VStack,
  Avatar,
  GridItem,
  Grid,
  Image,
  useColorModeValue,
  SimpleGrid,
  // keyframes,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const animation = keyframes`
from {
 background:inherit
}
to{
    background:gray.100
}
`;

const Gallary = () => {
  const [galleryPageData, setGalleryPageData] = useState([]);
  const myAnimation = `${animation} infinite 1s`;

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/get/gallery");
      setGalleryPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="heading">
          <h1>
            Photos <span>Gallery</span>
          </h1>
        </div>

        <div className="box">
          {/* -----------------first column--------------- */}
          <div className="dream"></div>
        </div>
      </div>

      {/* ------------------------------------================== */}

      <div
        style={{
          backgroundImage: "linear-gradient(to right, #68D391, #F687B3)",
        }}
      >
        {/* <Header /> */}
        {/*  banner   */}
        <Breadcrumb
          pageName="Gallary"
          pageTitle="Gallary"
          id="#gallery"
          img_url={`
        https://bssadev.brandradiator.in/img/medal-laao-newbanner-desktop.jpg`}
        />

        {/* banner ends */}



        <div>
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["18", "42"]}
            letterSpacing="wide"
            color="teal.800"
            px={[4, 10, 20]}
            m={4}
            p={4}
            _hover={{
              animation: myAnimation,
            }}
          >
            गेलरी
          </Text>
          <SimpleGrid
            columns={[1, 2, 2, 3]}
            gap={6}
            //   bgGradient="linear(to-r, green.200, pink.500)"
          >
            {/* --------------------for 1st container for image and text --------------------- */}
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              maxW={"300px"}
              w={"full"}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              m={9}
              overflow={"hidden"}
            >
              {/*-------------------------------------  image container--------------------------*/}
              <motion.VStack
                h="2px"
                bg={"gray.600"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
                animate={{
                  opacity: 1,
                }}
                // before animate the design is
                initial={{
                  opacity: 0.1,
                }}
                transition={{
                  type: "spring",
                  // stiffness:100,
                  damping: 100,
                }}
              >
                <Image
                  src={
                    "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                  fill
                  alt="Example"
                  _hover={{
                    animation: myAnimation,
                  }}
                  h="auto"
                  w="auto"
                />
              </motion.VStack>

              {/*-------------------------------------   image container end--------------------------*/}

              <VStack>
                <Text
                  color={"green.500"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                  p="3"
                >
                  example fro framer-motion
                </Text>
                <Heading
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost your conversion rate
                </Heading>
                <Text color={"gray.500"} pt={30}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </Text>
              </VStack>
            </GridItem>
            {/* -----------------------------   for 1st container ends  --------------------- */}
          </SimpleGrid>
        </div>

      </div>
    </>
  );
};

export default Gallary;
