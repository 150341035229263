export const TenderData = [
  {
    "key": "T25",
    "eventName": "tenders",
    "date": "4 April 2025",
    "expiryDate": "15 April 2025",
    "name": "RFP for DESIGN, DEVELOPMENT, TESTING, COMMISSIONING, INTEGRATION AND MAINTENANCE OF ATHLETE ASSESSMENTS AND TALENT IDENTIFICATION SYSTEM AND GAMES MANAGEMENT SYSTEM AT DISTRICT AND STATE LEVEL FOR BIHAR MASHAAL 2025.",
    "corrigendum": "",
    // "meetUrl": "https://meet.google.com/rox-xufc-wqf",
    "url": "pdf/notices/133874552464725547 (1).pdf"
  },
  {
    "key": "T24",
    "eventName": "tenders",
    "date": "24 Mar",
    "expiryDate": "",
    "name": "Corrigendum for Engagement of Overlays and Sports Operations Agency for KIYG, 2025, Bihar. RFP No. BSSA/Tender/24-25/OSOA/KIYG/1163/25.",
    "corrigendum": "",
    // "meetUrl":"https://meet.google.com/rox-xufc-wqf",
    "url": "pdf/notices/Corrigendum Related to KIYG.PDF"
  },
  {
    "key": "T23",
    "eventName": "tenders",
    "date": "Tuesday 18 Mar  •  15:00–16:00",
    "expiryDate": "",
    "name": "Pre-Bid Meeting for Engagement of Overlays and Sports Operations Agency for KIYG, 2025, Bihar. RFP No. BSSA/Tender/24-25/OSOA/KIYG/1163/25.",
    "corrigendum": "",
    "meetUrl":"https://meet.google.com/rox-xufc-wqf",
    "url": "https://meet.google.com/rox-xufc-wqf"
  },
  {
    "key": "T22",
    "eventName": "tenders",
    "date": "18.03.2025",
    "expiryDate": "07.04.2025",
    "name": "Engagement of Overlays and sport Operations Agency for Khelo India Youth Games 2025 Bihar",
    "corrigendum": "",
    // "meetUrl":"Online Meet link : https://meet.google.com/qgi-qioq-ncj",
    "url": "img/notices/133862326302838424_250314_134657.pdf"
  },
  {
    "key": "T21",
    "eventName": "tenders",
    "date": "07.02.2025 (3:00pm – 5:00pm)",
    "expiryDate": "",
    "name": "Pre bid meeting for RFP Related to ENGAGEMENT OF AGENCY FOR THE CEREMONIES OF KHELO INDIA YOUTH GAMES 2025, BIHAR - Corrigendum ",
    "corrigendum": "",
    // "meetUrl":"Online Meet link : https://meet.google.com/qgi-qioq-ncj",
    "url": "img/notices/KIYG  Cereomonies Corrigendum.PDF"
  },
  {
    "key": "T20",
    "eventName": "tenders",
    "date": "07.02.2025 (3:00pm – 5:00pm)",
    "expiryDate": "",
    "name": "Pre bid meeting for RFP Related to ENGAGEMENT OF AGENCY FOR THE CEREMONIES OF KHELO INDIA YOUTH GAMES 2025, BIHAR ",
    "corrigendum": "",
    "meetUrl":"Online Meet link : https://meet.google.com/qgi-qioq-ncj",
    "url": "https://meet.google.com/qgi-qioq-ncj"
  },
  {
    "key": "T19",
    "eventName": "tenders",
    "date": "29.01.2025",
    "expiryDate": "24.02.2025",
    "name": "RFP Related to ENGAGEMENT OF AGENCY FOR THE CEREMONIES OF KHELO INDIA YOUTH GAMES 2025, BIHAR",
    "corrigendum": "",
    "url": "img/notices/CeremoniesofKIYGGamesTender.PDF"
  },
  {
    "key": "T18",
    "eventName": "tenders",
    "date": "22.01.2025",
    "expiryDate": "-",
    "name": "Corrigendum 02 Related to Empanelment of Agencies for Supply of Sports Equipment for Bihar State Sports Authority for a Period of 03 Years (PR No. 015314 (B&C) 2024-25)",
    "corrigendum": "",
    "url": "img/notices/Corregnedum 02 Related to Sports Equipments Tender 2025.PDF"
  },
  {
    "key": "T13",
    "eventName": "tenders",
    "date": "24.12.2024",
    "expiryDate": "20.01.2025",
    "name": "Corrigendum Related to Empanelment of Agencies for Supply of Sports Equipment for Bihar State Sports Authority for a Period of 03 Years (PR No. 015314 (B&C) 2024-25)",
    "corrigendum": "",
    "url": "img/notices/SportsEquipmentsCorigendum.PDF"
  },
  {
    "key": "T12",
    "eventName": "tenders",
    "date": "24.12.2024",
    "expiryDate": "20.01.2025",
    "name": "EMPANELMENT oF AGENCIES FOR SUPPLY OF SPORTS EQUIPMENT FOR BIHAR STATE SPORTS AUTHORITY FOR A PERIOD OF 03 YEARS",
    "corrigendum": "",
    "url": "img/notices/Empanelment of Agency for Supply Sports Equipments for BSSA.pdf"
  },
  {
    "key": "T11",
    "eventName": "tenders",
    "date": "02/11/2024,",
    "expiryDate": "05/11/2024",
    "name": "Request for quotation for purchase of special equipment for practice for the players of fencing sports discipline practicing at Khelo India Small Center, Motihari",
    "corrigendum": "",
    "url": "img/notices/letterno-4309.PDF"
  },
  {
    "key": "T14",
    "eventName": "tenders",
    "date": "30/10/2024,",
    "expiryDate": "03/11/2024",
    "name": "The upcoming Bihar Women's Asian Hockey Champion Trophy 2024 Khel Vidya is to be organized from 11-20 November 2024 at Rajgir, Nalanda. Requesting Quotation for Necessary materials required for coaches accommodation, girls hostel, staff room in the aforesaid event.",
    "corrigendum": "",
    "url": "img/notices/Tender_BSSA_Letter No 4179_30.10.24.pdf"
  },
  {
    "key": "T10",
    "eventName": "tenders",
    "date": "28/10/2024,",
    "expiryDate": "01/11/2024",
    "name": "Quotation from various firms for preparing a theme song to showcase the language and culture of Bihar and to encourage the players in the organization of Bihar Women's Asian Hockey Championship Trophy 2024 (Letter No.: 4090)",
    "corrigendum": "",
    "url": ""
  },
  {
    "key": "T13",
    "eventName": "tenders",
    "date": "26/10/2024,",
    "expiryDate": "02/11/2024",
    "name": "Requesting quotation for work related to NVR with Control Room Setup, Power Cable, PVC Pipe, Patch code, Segat, Cat6 Cable, Smart TV, Hdpe Pipe, Mounting Pole, HDMI Cable, UPS, Baggage Scanner at Rajgir Sports Academy for continuous monitoring of security arrangements in the organization of Bihar Women's Asian Hockey Championship Trophy 2024.",
    "corrigendum": "",
    "url": "img/notices/Tender_BSSA_Letter No 4082_26.10.24.pdf"
  },
  {
    "key": "T12",
    "eventName": "tenders",
    "date": "25/10/2024,",
    "expiryDate": "01/11/2024",
    "name": "Requesting quotation related to installation of cameras in Rajgir Sports Academy for continuous monitoring along with security arrangements during the organization of Bihar Women's Asian Hockey Championship Trophy 2024.",
    "corrigendum": "",
    "url": "img/notices/Tender_BSSA_Letter No 4056_25.10.24.pdf"
  },
  {
    "key": "T9",
    "eventName": "tenders",
    "date": "24/10/2024,",
    "expiryDate": "30/10/2024",
    "name": "Quotation related to Limited Tender Enquiry from empanelled agencies for the following works: 1. Digital Ads & Influencer, 2. Designing, 3. Logistics, 4. Concept (Event), 5. Concepulization, and 6. Event Management for organizing Bihar Women's Asian Hockey Championship Trophy 2024 (Letter No.: 4033)",
    "corrigendum": "",
    "url": ""
  },
  {
    "key": "T8",
    "eventName": "tenders",
    "date": "10/07/2024,",
    "expiryDate": "----",
    "name": "NOTICE RELATED TO SUBMISSION OF TENDER RELATED TO EMPANELMENT OF TRANSPORATION AGENCY(PR No -001689(B & C) 2024 - 25",
    "corrigendum": "",
    "url": "img/tenders/L.No. 2457 dt.10.07.2024.PDF"
  },
  {
    "key": "T8",
    "eventName": "tenders",
    "date": "05/07/2024,",
    "expiryDate": "10/07/2024",
    "name": "Corrigendum 02 related to the tender published for operating the mess (canteen) at Patliputra Sports Complex, Kankarbagh, Patna",
    "corrigendum": "",
    "url": "img/tenders/CORRIGNEDUM_2_RELATED_TO_MESS.PDF"
  },
  {
    "key": "T7",
    "eventName": "tenders",
    "date": "02/07/2024,",
    "expiryDate": "",
    "name": "Corrigendum related to the suggestions and other points received in the pre-bid meeting related to sports kit on 24/06/2024",
    "corrigendum": "",
    "url": "img/tenders/Corrigendum Related to Sports Kit RFP.PDF"
  },
  {
    "key": "T6",
    "eventName": "tenders",
    "date": "02/07/2024,",
    "expiryDate": "",
    "name": "Corrigendum regarding tender for selection of catering agency for Patliputra Sports Complex Hostel",
    "corrigendum": "",
    "url": "img/tenders/Corrigendum 2 related to MESS.PDF"
  },
  {
    "key": "T5",
    "eventName": "tenders",
    "date": "26/06/2024,",
    "expiryDate": "",
    "name": "Corrigendum related to the amount of EMD and TENDER FEE of the tender related to sports kit",
    "corrigendum": "",
    "url": "img/tenders/Corrigendum_related_to_Sports_Ki.PDF"
  },

  {
    "key": "T4",
    "eventName": "tenders",
    "date": "25/06/2024,",
    "expiryDate": "",
    "name": "New date for sports kit tenders for Bihar State Sports Authority",
    "corrigendum": "",
    "url": "img/tenders/NewDateforPrebidl.PDF"
  },
  {
    "key": "T3",
    "eventName": "tenders",
    "date": "24/06/2024,",
    "expiryDate": "08/07/2024",
    "name": "Selection of agency for supply of sports kits for Bihar State Sports Authority for a period of 03 years",
    "corrigendum": "",
    "url": "img/tenders/SPORTSKITFULLPDFFILE.pdf"
  },
  {
    "key": "T2",
    "eventName": "tenders",
    "date": "13/06/2024",
    "expiryDate": "05/07/2024",
    "name": "Tender for selection of catering agency for Patliputra Sports Complex Hostel, Patna for a period of five years",
    "corrigendum": "",
    "url": "img/tenders/MESSRPFFULLDETAILSPDFFILE.pdf"
  },
  {
    "key": "T1",
    "eventName": "tenders",
    "date": "12/06/2024",
    "expiryDate": "21/06/2024",
    "name": "EMPANELMENT OF TRANSPORATION AGENCY FOR BIHAR STATE SPORTS AUTHORITY FOR A PERIOD OF 03 YEARS",
    "corrigendum": "",
    "url": "img/tenders/TRAVEL_AGENCY_RFP_FULL_DETAILS_WORD_FILE.pdf"
  }
  // {
  //     "key": "T10",
  //     "eventName": "tenders",
  //     "date": "_",
  //     "expiryDate": "_",
  //     "name": "Corrigendum related to Travel Agency RFP",
  //     "corrigendum": "",
  //     "url": "img/tenders/Corrigendum_Travel Agency.docx"
  // },
  // {
  //     "key": "T9",
  //     "eventName": "tenders",
  //     "date": "11/01/2024",
  //     "expiryDate": "22/01/2024",
  //     "name": "EMPANELMENT OF TRANSPORATION AGENCY FOR BIHAR STATE SPORTS AUTHORITY FOR A PERIOD OF 03 YEAR",
  //     "corrigendum": "",
  //     "url": "img/tenders/RFP_FOR_Travel_Agency_for_BSSA_PDF_full_details.pdf"
  // },

  // {
  //     "key": "T8",
  //     "eventName": "tenders",
  //     "date": "15/12/2023",
  //     "expiryDate": "_____",
  //     "name": "ESTABLISHMENT OF PROJECT MONITORING UNIT FOR PROMOTING E-SPORTS OF BIHAR FOR A PERIOD OF 03 YEARS",
  //     "corrigendum": "",
  //     "url": "img/tenders/Corrigendum_E-Sports.pdf"
  // },
  // {
  //     "key": "T7",
  //     "eventName": "tenders",
  //     "date": "11/12/2023",
  //     "expiryDate": "18/12/2023",
  //     "name": "Regarding purchasing sports equipment for Center of Excellence",
  //     "corrigendum": "",
  //     "url": "img/tenders/weightliftingcenterofexcellence.pdf"
  // },
  // {
  //     "key": "T6",
  //     "eventName": "tenders",
  //     "date": "06/12/2023",
  //     "expiryDate": "15/12/2023",
  //     "name": "ESTABLISHMENT OF PROJECT MONITORING UNIT FOR PROMOTING E-SPORTS IN THE STATE OF BIHAR FOR A PERIOD OF 03 YEARS",
  //     "corrigendum": "",
  //     "url": "img/tenders/ESTABLISHMENTOFPROJECTMONITORING.pdf"
  // },
  // {
  //     "key": "T1",
  //     "eventName": "tenders",
  //     "date": "21/08/2023",
  //     "expiryDate": "11/09/2023",
  //     "name": "RFP for Selection of catering agency for Patliputra sports complex hostel, Patna for 05 years",
  //     "corrigendum": "",
  //     "url": "img/tenders/RFP for Selection of catering agency for Patliputra sports complex hostel, Patna for 05 years.pdf"
  // },
  // {
  //     "key": "T2",
  //     "eventName": "tenders",
  //     "date": "24/07/2023",
  //     "expiryDate": "24/07/2026",
  //     "name": "Letter No. 1003 Dated 24.07.2023 (4).pdf",
  //     "corrigendum": "",
  //     "url": "img/tenders/letter_number_1003_dated_24072023.pdf"
  // },
  // {
  //     "key": "T3",
  //     "eventName": "tenders",
  //     "date": "24/07/2023",
  //     "expiryDate": "24/07/2026",
  //     "name": "RFP COMPREHENSIVE HOUSEKEEPING, MAINTENANCE AND OPERATION CONTRACT (FOR 03 YEARS) FOR STADIUMS, OFFICES, HOSTELS AND OTHER ALLIED FACILITIES AT BIHAR STATE SPORTS AUTHORITY",
  //     "corrigendum": "",
  //     "url": "img/tenders/133343212303759748.pdf"
  // },
  // {
  //     "key": "T4",
  //     "eventName": "tenders",
  //     "date": "23/06/2023",
  //     "expiryDate": "23/06/2025",
  //     "name": "Corrigendum :(Pre Bid Queries) RFP for design, development, testing, commissioning, integration and maintenance of website at Bihar State Sports Authority (BSSA) for a period of 2 years",
  //     "corrigendum": "",
  //     "url": "img/tenders/TENDER.pdf"
  // },
  // {
  //     "key": "T5",
  //     "eventName": "tenders",
  //     "date": "23/06/2023",
  //     "expiryDate": "23/06/2025",
  //     "name": "Corrigendum :(Pre- bid- quarries) RFP for design, development, testing, commissioning, integration and maintenance of website at Bihar State Sports Authority (BSSA) for a period of 2 years  CamScanner 06-23-2023 13.31.09.pdf",
  //     "corrigendum": "",
  //     "url": "img/tenders/CamScanner 06-23-2023 13.31.09.pdf"
  // }
]